import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  CameraFeatureFlag,
  AnalyticsEvent,
  TimelineBimUrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { TimelineChartType } from "@evercam/ui"
import moment from "moment-timezone"
import { useCameraStore } from "@/stores/camera"
import { useSnapshotStore } from "@/stores/snapshots"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.Bim

interface TimelineBimGroupState {
  showBim: boolean
}

export const useTimelineBimGroupStore = defineStore({
  id: "timelineBimGroup",
  state: (): TimelineBimGroupState => ({
    showBim: false,
  }),
  getters: {
    hasBimCompare() {
      return useCameraStore().selectedCamera?.featureFlags?.includes(
        CameraFeatureFlag.BimCompare
      )
    },
    isDisabled(): boolean {
      return !this.hasBimCompare
    },
    isVisible(): boolean {
      return this.showBim && !this.isDisabled
    },
    group(): TimelineGroup {
      const groupConfig = {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
      }

      if (this.isDisabled) {
        return groupConfig as unknown as TimelineGroup
      }

      const bimMilestones = useBimCompareStore().bimAvailableDays?.map(
        (bimAvailableDay) => {
          return {
            ...{ bimAvailableDay },
            milestoneType: TimelineGroupId.Bim,
            color: TimelineColors.bimMilestones,
            timestamp: moment(bimAvailableDay, "DD/MM/YYYY").toISOString(),
            text: `BIM: ${moment(bimAvailableDay, "DD/MM/YYYY").format(
              "MMM Do YYYY"
            )}`,
            size: 28,
          }
        }
      )
      if (!useBimCompareStore().bimAvailableDays?.length) {
        bimMilestones.push({
          milestoneType: TimelineGroupId.Bim,
          color: TimelineColors.bimMilestones,
          startDate: useSnapshotStore().oldestSnapshotTimestamp(
            useCameraStore().selectedCamera?.id
          ),
          endDate: useSnapshotStore().latestSnapshotTimestamp(
            useCameraStore().selectedCamera?.id
          ),
        })
      }

      return {
        ...groupConfig,
        timelineConfig: {
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          height: 38,
          color: TimelineColors.bimMilestones,
          chartType: TimelineChartType.Dots,
          events: bimMilestones,
        },
      } as unknown as TimelineGroup
    },
  },
  actions: {
    changeBimeMilestonesVisibility(visibilityStatus) {
      if (this.showBim === visibilityStatus) {
        return
      }
      this.showBim = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibilityBimMilestones,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineBimUrlParams
  ) as (keyof TimelineBimGroupState)[],
})
